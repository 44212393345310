/* Service Header Styling */
.service-header {
    font-family: 'Poppins', sans-serif;
    color: #374785;
    margin-bottom: 2rem;
  }
  
  /* Service Card Styling */
  .service-card {
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .service-card .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 200px;
    object-fit: cover;
  }
  
  .service-card .card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #374785;
  }
  
  .service-card .card-text {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #6c757d;
  }
  
  .service-card .btn-primary {
    background-color: #374785;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .service-card .btn-primary:hover {
    background-color: #28344e;
  }
  