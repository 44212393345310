/* Container Styling */
.contact-container {
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  color: #374785;
}

/* Contact Details Styling */
.contact-details h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #374785;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.contact-details svg {
  margin-right: 0.5rem;
}

/* Contact Form Styling */
.contact-form .form-group {
  margin-bottom: 1.5rem;
}

.contact-form label {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  color: #495057;
}

.contact-form .form-control {
  border-radius: 10px;
  border: 2px solid #ced4da;
  padding: 0.75rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.contact-form .form-control:focus {
  border-color: #374785;
  box-shadow: none;
}

.contact-form .btn {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  padding: 0.75rem;
  border-radius: 10px;
  background-color: #374785;
}
