/* Styling for the gallery cards */
.gallery-card {
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    object-fit: cover;
    height: 300px;
  }
  
  .container {
    max-width: 1200px;
    margin: auto;
  }
  
  h1 {
    font-family: 'Poppins', sans-serif;
    color: #374785;
  }
  
  @media screen and (max-width: 576px) {
    .gallery-card .card-img-top {
      height: 200px;
    }
  }
  