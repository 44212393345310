.footer {
  background-color: #2c2f33;
  color: #ffffff;
}

.footer h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer ul {
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 0.5rem;
}

.footer ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer ul li a:hover {
  color: #ffdd57;
}

.map-container iframe {
  border-radius: 10px;
}

.btn-primary {
  background-color: #374785;
  border-color: #374785;
}

.btn-primary:hover {
  background-color: #2c3e50;
  border-color: #2c3e50;
}

.text-white {
  color: #ffffff !important;
}

.text-center {
  text-align: center;
}

@media (max-width: 768px) {
  .footer h5 {
    font-size: 1.25rem;
  }

  .text-center {
    text-align: center;
  }

  .footer .col-md-4, .footer .col-md-6 {
    text-align: center;
  }
}
