/* Custom background for the navbar */
.custom-navbar {
  background: #374785;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 2rem; /* Default padding */
}

/* Disable sticky behavior and reduce header size on mobile */
@media (max-width: 992px) {
  .custom-navbar {
    position: static;
    padding: 0.5rem 1rem; /* Reduced padding */
  }

  .custom-navbar .navbar-brand {
    font-size: 1.5rem; /* Smaller font size */
  }

  .custom-navbar .navbar-nav .nav-link {
    font-size: 1rem; /* Smaller font size */
    padding: 0.25rem 0.5rem; /* Reduced padding for links */
  }
}

/* Custom text styles for navbar links */
.custom-navbar .navbar-brand {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.custom-navbar .navbar-nav .nav-link {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.custom-navbar .navbar-nav .nav-link:hover {
  color: #ffddd2;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}

/* Custom styles for the navbar-toggler icon */
.custom-navbar .navbar-toggler-icon {
  filter: invert(100%);
}

/* Responsive styles */
@media (max-width: 992px) {
  .navbar-nav .nav-link {
    text-align: center;
    padding: 1rem 0;
  }

  .navbar-collapse {
    text-align: center;
  }
}
