/* General Styling */
.about-header {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #374785;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.about-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #374785;
  margin-bottom: 1.5rem;
}

.about-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  color: #495057;
  line-height: 1.7;
}

.about-link {
  color: #374785;
  text-decoration: underline;
}

.about-link:hover {
  color: #2c3e50;
}

/* Card Styling */
.card {
  border: none;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 2rem;
}

.img-fluid {
  border-radius: 15px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .about-header {
    font-size: 2rem;
  }

  .card-body {
    padding: 1.5rem;
  }

  .img-fluid {
    max-height: 200px;
  }
}
